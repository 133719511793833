<template>
    <div>
        <br>
        <h3 class="text-dark">Create New User</h3>
        <br>
        <my-box>
            <b-button size="sm" variant="secondary" class="mr-2" squared>Advanced</b-button>
        </my-box>
        <br>

        <!-- Singleton -->
        <my-box>
            <b-form @submit="createUser">
                <b-row>
                    <b-col>
                        <b-form-group label="Firstname">
                            <b-form-input size="sm" required v-model="user.firstname" class="rounded-0"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Lastname">
                            <b-form-input size="sm" required v-model="user.lastname" class="rounded-0"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Username">
                            <b-form-input size="sm" required v-model="user.username" class="rounded-0"></b-form-input>
                        </b-form-group>
                        <b-form-group label="E-Mail">
                            <b-form-input size="sm" required v-model="user.private_email" type="email" class="rounded-0"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>    
                <div class="text-right">
                    <b-button variant="primary" type="submit" squared size="sm">Create New User</b-button>
                </div>
            </b-form>
        </my-box>
        <br>

        <!-- Advanced -->
        <!-- <my-box>
            <b-form>
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Number of Users">
                            <b-form-spinbutton size="sm" class="rounded-0" min="1" max="100"></b-form-spinbutton>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Firstname">
                            <b-form-input size="sm" required class="rounded-0"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Lastname">
                            <b-form-input size="sm" required class="rounded-0"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Username">
                            <b-form-input size="sm" required class="rounded-0"></b-form-input>
                        </b-form-group>
                        <b-form-group label="E-Mail">
                            <b-form-input size="sm" required class="rounded-0"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>    
                <div class="text-right">
                    <b-button variant="primary" type="submit" squared size="sm">Create New Users</b-button>
                </div>
            </b-form>
        </my-box> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: {
                username: null,
                firstname: null,
                lastname: null,
                private_email: null
            }
        }
    },
    methods: {

        /**
         * Create new user data
         */
        createUser(event) {
            event.preventDefault()
            this.$store.dispatch('credentials/user/post', { data: { data: this.user }})
            .then(res => {
                this.$router.push({ path: '/credentials/users' })
            })
            .catch(err => {
                this.errorMsg = error.status + ' - ' + error.statusText
            })
        }
    },
    created() {

    },
};
</script>